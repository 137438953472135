import React from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'

const ThankYouPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div className='flex justify-center w-full'>
      <div className='flex max-w-2xl w-full flex-col justify-center items-center py-20'>
        <h1 className='mb-16'>Vielen Dank für Ihre Anfrage!</h1>
        <p className='text-center'>Wir werden Ihre Nachricht schnellstmölgich bearbeiten und uns mit Ihnen in Verbindung setzen.</p>
      </div>
    </div>
  </Layout>
)

export default ThankYouPage
